import PropTypes from "prop-types"
import React from "react"
import mapboxgl from "mapbox-gl"


class MapAsList extends React.Component {
  componentDidMount() {
    if (typeof (window) !== 'undefined' &&
      typeof (mapboxgl) !== "undefined") {
      mapboxgl.accessToken = 'pk.eyJ1IjoibWVyZ2VjaGljYWdvIiwiYSI6ImNrMHljMThsdDBlYW4zY29ib2JhYXhlZWYifQ.uem2bbxL5oqjRsx9FoqIgQ';

      window.findMapMarker = function (e) {
        e.stopPropagation();
        if (e.target != null) {
          var markerLabel = e.target.querySelector('h3');
          if (markerLabel != null) {
            window.findMatchingMarker(markerLabel.innerText);
          }
        }
      }

      window.findMatchingMarker = function (searchTerm) {
        let resultMarker = document.querySelectorAll("#map-container .marker");    // all marker
        for (let el of resultMarker) {
          if (el.dataset.title.includes(searchTerm)) {
            el.click();  //mark current in text list
            break
          }
        }
      }
    }
  }

  render() {
    if (typeof (window) !== 'undefined') {
      const { mallData } = this.props;
      const filteredMalls = mallData.features.filter(document => document.displayed);

      window.mallData = mallData;
      if (filteredMalls.length <= 0) {
        return (
          <div className="no-reults">
            <p>
              <strong>No results near your location.</strong>
            </p>
            <p>Use the map to locate all of our locations. Zoom in to select a location and see details.</p>
            <p>Or try a new search entry above.</p>
          </div>
        )
        }
        if (filteredMalls.length > 500) {
            return (
                <div className="no-reults">
                    <p>
                        <strong>Searching malls...</strong>
                    </p>
                </div>
            )
        }
      return (
        <>
          {filteredMalls.map((document, i) => (
            <div className="result" onClick={window.findMapMarker} key={i} >
              <div className="L">
                <h3>{document.properties.title}</h3>
                <p>{Math.round(document.properties.distance * 100) / 100} mi</p>
                <p className="address">
                  {document.properties.address}, {document.properties.city}, {document.properties.state} {document.properties.zip}
                </p>

                      {document.properties.active && !document.properties.closed && !document.properties.tempclosed ? <a className="btn-forward" href={document.properties.link}>View Details</a> : <div></div>}

                      {document.properties.active && !document.properties.closed && !document.properties.tempclosed && document.properties.reservation !== "" && document.properties.reservation !== null && document.properties.reservation !== "#" ? (
                    <a className="btn-forward" href={document.properties.reservation + '&utm_medium=web-link&utm_source=where-is-santa'} target="_blank" rel="noopener noreferrer">Make a Reservation</a>
                ) : (
                <div></div>
                )}

                      {!document.properties.active || document.properties.closed ? <p className="santa-unavailable">This year Cherry Hill Programs is not offering a real-bearded Santa experience at this location.</p> : document.properties.tempclosed ? <p className="santa-unavailable">This location is currently not offering a Cherry Hill Programs real-bearded Santa experience due to COVID restrictions in the area or other factors. Please plan a Virtual Visit with Santa or explore other locations nearby.</p> : <p></p>}

                      {!document.properties.active || document.properties.closed ? <a className="btn-forward virtual" href="https://www.createholidaymagic.com/" target="_blank">Plan a Virtual Visit</a> : document.properties.tempclosed ? <a className="btn-forward virtual" href="https://www.createholidaymagic.com/" target="_blank">Plan a Virtual Visit</a> : <div></div>}
              </div>
              <div className="R">
                      {document.properties.active == true && document.properties.closed == false ? <a className="info" href={document.properties.link}>i</a> : <a className="question" href={document.properties.link}>?</a>}
              </div>
            </div>
          ))}
        </>
      )
    }
    return (<></>)
    // return <h1>Hi</h1>
  }

}

MapAsList.propTypes = { title: PropTypes.string, }
MapAsList.defaultProps = { title: ``, }

export default MapAsList
