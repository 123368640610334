import PropTypes from "prop-types"
import React from "react"

const AdsHome = ({ title, ads }) => (
    <div className="layout-width" id="homeAds">
        <div className="text">
            <h2>From Us to You</h2>
            <p className="wide">Not all gifts are found under the tree. Enjoy these special offers from Santa's partners.</p>
            <p className="narrow">Not all gifts are found under the tree. Enjoy these special offers from Santa's partners.</p>
        </div>
        <div className="cardCont">
            {ads.map((ad, index) => {
                return (ad.node.Feature_Home_Page === true ? <a className="card" href={ad.node.Link} style={{ backgroundImage: `url(${ad.node.Image ? ad.node.Image.childImageSharp.fluid.src : ""})`}} target="_blank">
                            <div className="card-top">
                                <img src={ad.node.Logo.childImageSharp.fluid.src} alt={ad.node.Offer_Headline} />
                            </div>
                            <div className="card-bot">
                                <h2>{ad.node.Offer_Headline}</h2>
                                <p>{ad.node.Offer_Content}</p>
                            </div>
                </a> : <></> )
            })}
        </div>
    </div>
)
AdsHome.propTypes = { title: PropTypes.string, }
AdsHome.defaultProps = { title: ``, }

export default AdsHome
