
var getFullUrl = function (abbreviation, title, mallnumber) { 
    let statename = '';
    //console.debug("A: " + abbreviation + "T: " + title + "M: " + mallnumber)
    switch (abbreviation.replace(/ /g, '')) {
        case 'AL':
            statename = 'alabama';
            break;
        case 'AK':
            statename = 'alaska';
            break;
        case 'AZ':
            statename = 'arizona';
            break;
        case 'AR':
            statename = 'arkansas';
            break;
        case 'CA':
            statename = 'california';
            break;
        case 'CO':
            statename = 'colorado';
            break;
        case 'CT':
            statename = 'connecticut';
            break;
        case 'DE':
            statename = 'delaware';
            break;
        case 'FL':
            statename = 'florida';
            break;
        case 'GA':
            statename = 'georgia';
            break;
        case 'HI':
            statename = 'hawaii';
            break;
        case 'ID':
            statename = 'idaho';
            break;
        case 'IL':
            statename = 'illinois';
            break;
        case 'IN':
            statename = 'indiana';
            break;
        case 'IA':
            statename = 'iowa';
            break;
        case 'KS':
            statename = 'kansas';
            break;
        case 'KY':
            statename = 'kentucky';
            break;
        case 'LA':
            statename = 'louisiana';
            break;
        case 'ME':
            statename = 'maine';
            break;
        case 'MD':
            statename = 'maryland';
            break;
        case 'MA':
            statename = 'massachusetts';
            break;
        case 'MI':
            statename = 'michigan';
            break;
        case 'MN':
            statename = 'minnesota';
            break;
        case 'MS':
            statename = 'mississippi';
            break;
        case 'MO':
            statename = 'missouri';
            break;
        case 'MT':
            statename = 'montana';
            break;
        case 'NE':
            statename = 'nebraska';
            break;
        case 'NV':
            statename = 'nevada';
            break;
        case 'NH':
            statename = 'newhampshire';
            break;
        case 'NJ':
            statename = 'newjersey';
            break;
        case 'NM':
            statename = 'newmexico';
            break;
        case 'NY':
            statename = 'newyork';
            break;
        case 'NC':
            statename = 'northcarolina';
            break;
        case 'ND':
            statename = 'northdakota';
            break;
        case 'OH':
            statename = 'ohio';
            break;
        case 'OK':
            statename = 'oklahoma';
            break;
        case 'OR':
            statename = 'oregon';
            break;
        case 'PA':
            statename = 'pennsylvania';
            break;
        case 'RI':
            statename = 'rhodeisland';
            break;
        case 'SC':
            statename = 'southcarolina';
            break;
        case 'SD':
            statename = 'southdakota';
            break;
        case 'TN':
            statename = 'tennessee';
            break;
        case 'TX':
            statename = 'texas';
            break;
        case 'UT':
            statename = 'utah';
            break;
        case 'VT':
            statename = 'vermont';
            break;
        case 'VA':
            statename = 'virginia';
            break;
        case 'WA':
            statename = 'washington';
            break;
        case 'WV':
            statename = 'westvirginia';
            break;
        case 'WI':
            statename = 'wisconsin';
            break;
        case 'WY':
            statename = 'wyoming';
            break;
        case 'PR':
            statename = 'puertorico';
            break;
        case 'QC':
            statename = 'quebec';
            break;
        case 'ON':
            statename = 'ontario';
            break;
        case 'AB':
            statename = 'alberta';
            break;
        case 'BC':
            statename = 'britishcoloumbia';
            break;
        case 'NB':
            statename = 'newbrunswick';
            break;
        case 'MB':
            statename = 'manitoba';
            break;
        case 'NL':
            statename = 'newfoundland';
            break;
        default:
            statename = abbreviation;
            break;
    }

    let smallTitle = title.toLowerCase();

    if (parseInt(mallnumber) < 10000)
        return (statename + '/' + smallTitle.replace(/ /g, ''));
    else {
        if (mallnumber !== undefined)
            return (statename + '/' + smallTitle.replace(/ /g, '') + mallnumber);
        else
            return (statename + '/' + smallTitle.replace(/ /g, ''));
    }
        

};

module.exports.getFullUrl = getFullUrl;