import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo1.svg";

export default class HeaderResults extends React.Component {
    componentDidMount() {
        if(this.props.displayAdFilter) {
            document.getElementById('formHeader').classList.add('narrow');
            document.getElementById('adFilter').classList.remove('hide');
        } else {
            document.getElementById('formHeader').classList.remove('narrow');
            document.getElementById('adFilter').classList.add('hide');
        }

    }
    render() {
        return (
            <div id="header" className="cols">
            <div className="col3">
            <a href="/" id="homeLink">Where is Santa?</a>
            </div>
            <div className="col3">
                <div id="formHeader" className="form">
                    <div id="map-search"></div>
                    <a href="#" className="formSearchBtn" aria-label="Search"></a>
                </div>
                <div id="adFilter" className="ad-select">
                    <select value={'/?skip-landing=1&ad=' + this.props.selectedAd} defaultValue={'/?skip-landing=1&ad=' + this.props.selectedAd}>
                        <option value=''>Select</option>
                    {this.props.adsData.map((item, key) => <option value={'/?skip-landing=1&ad=' + item.node.Title}>{item.node.Title}</option>)}
                    </select>
                </div>
            </div>
            <div className="col3 hasLogo">
                <a href="https://cherryhillprograms.com/" rel="nofollow"  target="_blank">
                    <img src={logo} alt="Cherry Hill Programs logo" /></a>
            </div>
        </div>
        );
    }
}

