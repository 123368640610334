import React from "react"
import HeaderResults from "../components/header-results"
import MapAsList from "../components/map-as-list"
import Map from '../components/map';
import SEO from "../components/seo"
import MapSearch from '../components/map-search';
import { Helmet } from "react-helmet";
import HomeHero from "../components/home-hero"
import AdsHome from "../components/ads-home"
import Footer from "../components/footer"
import GEOAPI from "../components/geoapi"
import mapboxgl from "mapbox-gl";
import CookieWarning from "../components/cookiewarning";
import queryString from 'query-string';
import axios from 'axios';
import EmailSignUp from "../components/email-signup";
import { graphql } from 'gatsby';
import { getFullUrl } from '../../url';
import whereissanta from "../images/WhereisSanta-nav.svg"
import { withPrefix, Link } from "gatsby"

// do not remove this import, it loads the styles for the page
import Layout from "../components/layout";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    if (typeof (window) !== 'undefined') {

      const parsed = queryString.parse(window.location.search);
      let adFilter = parsed.ad === undefined ? '' : parsed.ad;

      console.log('adFilter:', adFilter);

      this.state = { malls: this.prepareMallData(adFilter), mapMalls: [], adFilter: adFilter, ads1: this.props.data.allStrapiAd.edges }
    } else {
      this.state = { malls: this.prepareMallData(''), mapMalls: [], adFilter: '', ads1: this.props.data.allStrapiAd.edges }
    }
        

    this.sortAndHideMalls = this.sortAndHideMalls.bind(this);
  }
        
  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    const { email, utm_source } = parsed;
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    }
    const url = 'https://marketo.whereissanta.com/';
    
    if (parsed.email) {

      axios.post(url, { email }, axiosConfig)
        .then(response => {
          const hash = response.data;
          if (window.Munchkin) {
            window.Munchkin.munchkinFunction('associateLead', {
              'Email': encodeURI(email),
              'LeadSource': utm_source
            }, hash);
          }
        }).catch(err => console.log(err))
    }


    mapboxgl.accessToken = 'pk.eyJ1IjoibWVyZ2VjaGljYWdvIiwiYSI6ImNrMHljMThsdDBlYW4zY29ib2JhYXhlZWYifQ.uem2bbxL5oqjRsx9FoqIgQ';

    if (window.location.search.indexOf('?skip-landing=1') > -1) {
      document.getElementById('indexPage').style.display = 'none';
      document.getElementById('resultsPage').style.overflow = 'visible';

      var newParent = document.getElementById('formHeader');
      var oldParent = document.getElementById('formLanding');

      while (oldParent.childNodes.length > 0) {
        newParent.appendChild(oldParent.childNodes[0]);
      }
    }
  }

  sortAndHideMalls(malls) {
    this.setState({ malls })
  }



    getClosedStatus(mall) {
        return mall.Closed;
    }

    getTempClosedStatus(mall) {

        var closedStatus = false;

        var today = new Date();
        today.setHours(0, 0, 0, 0);

        mall.Hours.forEach((hour) => {

            var varDate = new Date(hour.Day); //dd-mm-YYYY
            if (varDate.getDate() === today.getDate() && varDate.getMonth() === today.getMonth() && varDate.getFullYear() == today.getFullYear()) {
                if (hour.Status === 'Closed - Special')
                    closedStatus = true;
            }

        });

        return closedStatus;

    }

  prepareMallData(adParameter) {
    console.log('adparameter:', adParameter);
    const mallData = this.props.data.allStrapiMall.edges;

    const mallMapData = {
      'features': [],
      'type': 'FeatureCollection'
    };

    

    mallData.forEach((mall) => {

        if ((adParameter === '' || mall.node.Ads.filter(ad => ad.Title === adParameter).length > 0)) {

            mallMapData['features'].push({
              'displayed': false,
              'type': 'Feature',
              'place_name': '🎅🏻 ' + mall.node.Title,
              'id': mall.node.id,
              'place_type': ['park'],
              'properties': {
                'id': mall.node.id,
                  'link': getFullUrl(mall.node.State, mall.node.Title, mall.node.Mall_Number),
                'title': mall.node.Title,
                'description': mall.node.Title,
                'address': mall.node.Address,
                'city': mall.node.City,
                'state': mall.node.State,
                'zip': mall.node.Zip,
                'reservation': mall.node.Reservation,
                'active': mall.node.Active,
                'closed': this.getClosedStatus(mall.node),
                'tempclosed': this.getTempClosedStatus(mall.node)

              },
              'geometry': {
                'coordinates': [
                  mall.node.Longitude,
                  mall.node.Latitude
                ],
                'type': 'Point'
              },
              'center': [
                mall.node.Longitude,
                mall.node.Latitude
              ]
            })
      }
    });

    console.log(mallMapData['features'].length);

    return mallMapData;
  }

  render() {
    const ads = this.props.data.allStrapiAd.edges;

    const malls = this.prepareMallData(this.state.adFilter);
    
    return (
      <>
        <Helmet>
                
        </Helmet>
            <SEO title={`Where is Santa`} description={`The magic of the holidays is here, and Santa Claus is closer than you think. Bring your family and friends for professional quality photos with Santa.`} />
        <GEOAPI />
        <div className="cols" id="indexPage">
                <div className="full-width">
                    <div className="nav"><div className="nav-item1"><img src={whereissanta} alt="Where Is Santa?" /></div><div className="nav-item2"><div><a href="http://www.cherryhillprograms.com/reopening" target="_blank">About Your Visit With Santa</a></div><div><a href="https://www.createholidaymagic.com" target="_blank">Visit with Santa Online</a></div></div></div>
            <HomeHero title={''} />
            <AdsHome title={''} ads={ads} />
            <EmailSignUp />
            <Footer title={''} />
          </div>
        </div>
        <div className="full-width" id="resultsPage">
          <HeaderResults adsData={this.state.ads1} selectedAd={this.state.adFilter} displayAdFilter={this.state.adFilter === '' ? false : true} />
          <div id="railL" className="cols">
            <div className="col3" id="listings">
              <MapAsList mallData={this.state.malls} />
            </div>
            <div className="col2-3">
              <Map mallData={malls} />
            </div>
            <div>
              <MapSearch mallData={malls} sortAndHideMalls={this.sortAndHideMalls} displayAdFilter={this.state.adFilter === '' ? false : true} />
            </div>
          </div>
        </div>
            <CookieWarning />
      </>
    );
  }
}

export default IndexPage

export const pageQuery = graphql`  
query IndexQuery {
  allStrapiAd {
    edges {
      node {
        Title
        Image {
          childImageSharp {
            fluid(quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Logo {
          childImageSharp {
            fluid(quality: 72) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Link
        Offer_Headline
        Offer_Content
        Feature_Home_Page
      }
    }
  }
  allStrapiMall(filter: {HideOnSite: {ne: true}, Latitude: {ne: null}, Longitude: {ne: null}}) {
    edges {
      node {
        id
        Title
        Active
        Closed
        Website
        Phone
        Reservation
        Latitude
        Longitude
        Address
        City
        State
        Zip
        Mall_Number
        Ads {
          Title
        }
        Hours {
            Start_Date
            End_Date
            Day
            Hours
            Status
        }
      }
    }
  }
}
`
