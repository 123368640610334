import PropTypes from "prop-types"
import React from "react"
import cookieIcon from "../images/icon-cookie.svg";
import iconX from "../images/icon-x.svg";




class CookieWarning extends React.Component 
{
  componentDidMount() 
      {
      this.el = document.querySelector('.m-cookie');
      this.btn = document.querySelector('.m-cookie .close');
      this._getCookie()
      }
  //
  render() 
      {
        return (
          <div id="cookiewarn" className="m-cookie">
            <div className="L">
              <img src={cookieIcon} alt="Close Icon" />
            </div>
            <div className="R">
              We all know Santa loves cookies, that's why we ask you to leave him some here. For more info, please view our privacy policy. <a target="_blank" rel="nofollow" href="https://www.cherryhillprograms.com/privacy-policy">Privacy Policy</a>
              <span className="X close">
                <img src={iconX} alt="Cookie Icon" />
              </span>
            </div>
          </div>
        );
      }
  //
  _bindListItemEvents() 
      {
        if (!this.btn) 
            { return }
        this.btn.addEventListener('click', () => 
          {
          this.el.classList.remove('show');
          localStorage.setItem('acknowledgedCookie', 'true');
          })
      }
  //
  _getCookie() 
        {
        let cook = localStorage.getItem('acknowledgedCookie');
        if (cook == undefined) 
          {
          this.el.classList.add('show')
          this._bindListItemEvents();
          }
        }
}



export default CookieWarning
