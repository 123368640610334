import React from 'react';

class GEOAPI extends React.Component
    {
    componentDidMount() 
        {
        this.allowGeo = document.getElementById('geoLocationTrigger');
        this.showPosition = function(position)
            {
              window.location.href ='/?skip-landing=1#9/' +position.coords.latitude+'/'+position.coords.longitude;
            }
        this.getLocation = function()
            {
            if (navigator.geolocation) 
                {navigator.geolocation.getCurrentPosition(this.showPosition);}
            else 
                { alert("Geolocation is not supported by this browser.");}
            }
        this.allowGeo.addEventListener('click', (event) => {
                event.preventDefault();
                this.getLocation();
              })
        }
    render() 
        {
        return (<></>);
        }
    }
 
 

export default GEOAPI
