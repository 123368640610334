import React from 'react';
import distance from '@turf/distance'
import './map.css';
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

class MapSearch extends React.Component {
  componentDidMount() {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWVyZ2VjaGljYWdvIiwiYSI6ImNrMHljMThsdDBlYW4zY29ib2JhYXhlZWYifQ.uem2bbxL5oqjRsx9FoqIgQ';

    var { mallData } = this.props;

    if(typeof(window) !== "undefined" && 
       typeof(document) !== "undefined" && 
       typeof(mapboxgl) !== "undefined" &&
       typeof(MapboxGeocoder) !== "undefined") {

      const searchBtn = document.querySelector('.formSearchBtn');
      const adSelect = document.querySelector('.ad-select select');

      const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: this.forwardGeocoder.bind(this),
          mapboxgl: mapboxgl,
          marker: false,
          countries: 'US,CA,AS,GU,MP,PR,UM,VI',
          filter: function (item) {
              // returns true if conditions are met for the mall. Currently not fully hooked up. Should be cms driven
              return (item.place_name !== "THISISAPLACEHOLDER") 
          }
        });
  
      window.malls = mallData;
  
      geocoder.on('result', function(ev) {
        try {
          if(typeof(window) !== "undefined") {
            window.dataLayer.push({'event': 'map-result'});
          }
        }
        catch(err) { console.debug(err); }



          const { mallData } = this.props;

          document.getElementById('indexPage').style.display = 'none';
          document.getElementById('resultsPage').style.overflow = 'visible';

          const queryString = require('query-string');
          const parsed = queryString.parse(window.location.search);
          let adFilter = '';
          adFilter = parsed.ad === undefined ? '' : parsed.ad;

          var pageUrl = '?skip-landing=1' + (parsed.ad === undefined ? '' : '&ad=' + parsed.ad) + window.location.hash;
          window.history.pushState('', '', pageUrl);

          var newParent = document.getElementById('formHeader');
          var oldParent = document.getElementById('formLanding');
          
          while (oldParent.childNodes.length > 0) {
              newParent.appendChild(oldParent.childNodes[0]);
          }
  
          var searchResult = ev.result.geometry;
      
          var options = { units: 'miles' };
          
          let lat1 = 0;
          let lat2 = 999;
          let lng1 = 0;
          let lng2 = -999;

          mallData.features.forEach(function(mall) {
              Object.defineProperty(mall.properties, 'distance', {
                  value: distance(searchResult, mall.geometry, options),
                  writable: true,
                  enumerable: true,
                  configurable: true
              });

              lat1 = lat1 < mall.geometry.coordinates[1] ? mall.geometry.coordinates[1] : lat1;
              lat2 = lat2 > mall.geometry.coordinates[1] ? mall.geometry.coordinates[1] : lat2;
              lng1 = lng1 > mall.geometry.coordinates[0] ? mall.geometry.coordinates[0] : lng1;
              lng2 = lng2 < mall.geometry.coordinates[0] ? mall.geometry.coordinates[0] : lng2;

          });

          mallData.features.sort(function(a, b) {
              if (a.properties.distance > b.properties.distance) {
                  return 1;
              }
              if (a.properties.distance < b.properties.distance) {
                  return -1;
              }
              // a must be equal to b
              return 0;
          });
  
          document.querySelectorAll('.marker.active').forEach((e)=>{e.classList.remove('active');});
          window.markers[mallData.features[0].properties.id]._element.classList.add('active');
          this.sortLonLat(0, searchResult, mallData);
  
          if (this.props.displayAdFilter) {

            window.map.fitBounds([[
              lng1,
              lat1
              ], [
                lng2,
                lat2
            ]]);

            window.map.flyTo({
              center: mallData.features[0].geometry.coordinates,
              zoom: 3
            });
          }
          else {
            window.map.flyTo({
              center: mallData.features[0].geometry.coordinates,
              zoom: 9
            });
          }

  
          window.malls = mallData;
  
      }.bind(this));
  
      window.map.on('moveend', function(ev) {
  
          if(typeof(window.malls) !== 'undefined') {
              var bounds = window.map.getBounds();
              var center = window.map.getCenter();
  
              
              var options = { units: 'miles' };
              window.malls.features.forEach(function(mall) {
                  Object.defineProperty(mall.properties, 'distance', {
                      value: distance([center.lng, center.lat], mall.geometry, options),
                      writable: true,
                      enumerable: true,
                      configurable: true
                  });
              });
          
              window.malls.features.sort(function(a, b) {
                  if (a.properties.distance > b.properties.distance) {
                      return 1;
                  }
                  if (a.properties.distance < b.properties.distance) {
                      return -1;
                  }
                  // a must be equal to b
                  return 0;
              });
      
              document.querySelectorAll('.marker.active').forEach((e)=>{e.classList.remove('active');});
              window.markers[window.malls.features[0].properties.id]._element.classList.add('active');
  
              for(var f=0; f<window.malls.features.length; f++) {
                  window.malls.features[f].displayed = this.inBounds(window.malls.features[f].geometry.coordinates, bounds);
              }
      
              this.props.sortAndHideMalls(window.malls);
          }
  
      }.bind(this));
  
      window.map.on('load', function(ev) {  
        try {
          if(typeof(window) !== "undefined") {
            window.dataLayer.push({'event': 'map-load'});
          }
        }
        catch(err) { console.debug(err); }

        if(typeof(window.malls) !== 'undefined') {
            var bounds = window.map.getBounds();
            var center = window.map.getCenter();
  
            let lat1 = 0;
            let lat2 = 999;
            let lng1 = 0;
            let lng2 = -999;

            var options = { units: 'miles' };
            window.malls.features.forEach(function(mall) {
                Object.defineProperty(mall.properties, 'distance', {
                    value: distance([center.lng, center.lat], mall.geometry, options),
                    writable: true,
                    enumerable: true,
                    configurable: true
                });

                lat1 = lat1 < mall.geometry.coordinates[1] ? mall.geometry.coordinates[1] : lat1;
                lat2 = lat2 > mall.geometry.coordinates[1] ? mall.geometry.coordinates[1] : lat2;
                lng1 = lng1 > mall.geometry.coordinates[0] ? mall.geometry.coordinates[0] : lng1;
                lng2 = lng2 < mall.geometry.coordinates[0] ? mall.geometry.coordinates[0] : lng2;
            });
        
            window.malls.features.sort(function(a, b) {
                if (a.properties.distance > b.properties.distance) {
                    return 1;
                }
                if (a.properties.distance < b.properties.distance) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
    
            if (this.props.displayAdFilter) {

              window.map.fitBounds([[
                lng1,
                lat1
                ], [
                  lng2,
                  lat2
              ]],{
                padding: {top: 50, bottom:50, left: 50, right: 50}
              });



              const queryString = require('query-string');
              const parsed = queryString.parse(window.location.search);
              let search = '';
              search = parsed.search === undefined ? '' : decodeURI(parsed.search);

              if (search !== '') {
                      var elem = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
                      elem[0].value = search;

                      var event1 = new Event('keydown');
                      event1.keyCode = 32;
                      elem[0].dispatchEvent(event1);

                      setTimeout(function() {
                        searchBtn.click();
                
                      }, 500);
              }


            }
            
            document.querySelectorAll('.marker.active').forEach((e)=>{e.classList.remove('active');});
            window.markers[window.malls.features[0].properties.id]._element.classList.add('active');
  
            for(var f=0; f<window.malls.features.length; f++) {
                window.malls.features[f].displayed = this.inBounds(window.malls.features[f].geometry.coordinates, bounds);
            }
    
            this.props.sortAndHideMalls(window.malls);
        }
  
      }.bind(this));
  
      document.getElementById('map-search').appendChild(geocoder.onAdd(window.map));
      
      searchBtn.addEventListener('click',function(e)
      {
        // simulates key with debounce.
        var elem = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
        var event = new Event('keydown');
        event.keyCode = 13;
        elem[0].dispatchEvent(event);

        setTimeout(function() {
          var elem = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
          var event = new Event('keyup');
          event.keyCode = 13;
          elem[0].dispatchEvent(event);
        }, 50);
      })

      adSelect.addEventListener('change',function(e)
      {
        var elem = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
        window.location.href=this.options[this.selectedIndex].value + '&search=' + encodeURI(elem[0].value);
      })
    }
  }

  inBounds = function(point, bounds) {
    var lng = (point[0] - bounds._ne.lng) * (point[0] - bounds._sw.lng) < 0;
    var lat = (point[1] - bounds._ne.lat) * (point[1] - bounds._sw.lat) < 0;
    return lng && lat;
  }

  sortLonLat(storeIdentifier, searchResult, mallData) {

    var lats = [mallData.features[storeIdentifier].geometry.coordinates[1], searchResult.coordinates[1]];
    var lons = [mallData.features[storeIdentifier].geometry.coordinates[0], searchResult.coordinates[0]];
  
    var sortedLons = lons.sort(function(a, b) {
      if (a > b) {
        return 1;
      }
      if (a.distance < b.distance) {
        return -1;
      }
      return 0;
    });
    var sortedLats = lats.sort(function(a, b) {
      if (a > b) {
        return 1;
      }
      if (a.distance < b.distance) {
        return -1;
      }
      return 0;
    });

    window.map.fitBounds([
      [sortedLons[0], sortedLats[0]],
      [sortedLons[1], sortedLats[1]]
    ], {
      padding: 100
    });
  }

  forwardGeocoder(query) {
    var { mallData } = this.props;
    var matchingFeatures = [];
    for (var i = 0; i < mallData.features.length; i++) {
        var feature = mallData.features[i];
        if (feature.properties.title.toLowerCase().search(query.toLowerCase()) !== -1) {
            matchingFeatures.push(feature);
        }
    }

    return matchingFeatures;
  }

  render() {
    return (
        <></>
    );
  }
}

export default MapSearch;
